<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    this.$router.push({ name: 'ProfileName' }).catch((error) => {
      console.log(error)
    })
  }
}
</script>
